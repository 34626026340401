import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/contact',
    name: 'contact-list',
    component: () => import('@/views/contacts/ContactsList.vue'),
  },
  {
    path: '/contact/:id',
    name: 'contact-view',
    component: () => import('@/views/contacts/ContactsView.vue'),
  },
  {
    path: '/contactform',
    name: 'contactform',
    component: () => import('@/views/contacts/ContactsForm.vue'),
  },
  {
    path: '/activity/list',
    name: 'activity-list',
    component: () => import('@/views/activity/ActivityList.vue'),
  },
  {
    path: '/activity/form',
    name: 'activity-form',
    component: () => import('@/views/activity/ActivityForm.vue'),
  },
  {
    path: '/sale/list',
    name: 'sale-list',
    component: () => import('@/views/sale/SaleList.vue'),
  },
  {
    path: '/sale/form',
    name: 'sale-form',
    component: () => import('@/views/sale/SaleForm.vue'),
  },
  {
    path: '/form/fnaList',
    name: 'fna-list',
    component: () => import('@/views/forms/fnaList.vue'),
  },
  {
    path: '/form/fnaList/:id',
    name: 'fna-view',
    component: () => import('@/views/forms/fnaItem.vue'),
  },
  {
    path: '/forms/fna',
    name: 'forms2',
    component: () => import('@/views/forms/fna.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/forms/fna/:id',
    name: 'forms3',
    component: () => import('@/views/forms/fna.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/note/list',
    name: 'note-list',
    component: () => import('@/views/note/NoteList.vue'),
  },
  {
    path: '/UserActivities/list',
    name: 'UserActivities-list',
    component: () => import('@/views/ListCurrentUserActivities/ListUserActivities.vue'),
  },
  {
    path: '/TodayBirthday/list',
    name: 'TodayBirthday-list',
    component: () => import('@/views/ListContactsTodayBirthday/listContactbirth.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/calendar/:id',
    name: 'calendar',
    component: () => import('@/views/activity/Calendar.vue'),
  },
  {
    path: '/calendar',
    name: 'calendarr',
    component: () => import('@/views/activity/Calendar.vue'),
  },
  {
    path: '/filtering',
    name: 'filtering',
    component: () => import('@/views/filtering/FilteringPage.vue'),
  },
  {
    path: '/company',
    name: 'companies',
    component: () => import('@/views/company/Company.vue'),
  },
  {
    path: '/company/:id',
    name: 'company-view',
    component: () => import('@/views/company/CompanyView.vue'),
  },
  {
    path: '/companyform',
    name: 'companyForm',
    component: () => import('@/views/company/CompanyForm.vue'),
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/documents/Documents.vue'),
  },
  {
    path: '/create-document/:id',
    name: 'create-document',
    component: () => import('@/views/documents/CreateDocument.vue'),
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
