<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar size="40px" v-bind="attrs" v-on="on">
        <v-icon size="30">
          {{ icons.mdiAccountCircle }}
        </v-icon>
      </v-avatar>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-avatar size="40px">
          <v-icon size="30" class="ms-4">
            {{ icons.mdiAccountCircle }}
          </v-icon>
          <!-- <v-img :src="require('@/assets/images/avatars/1.png')"></v-img> -->
        </v-avatar>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1"> {{ username }} </span>
          <!-- <small class="text--disabled text-capitalize">Admin</small> -->
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountCircle,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountCircle,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      username: '',
    }
  },

  mounted() {
    this.username = localStorage.getItem('username')
  },

  methods: {
    logout() {
      localStorage.clear()
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
