<template>
  <div>
    <v-app v-if="loggedIn">
      <v-app-bar app flat absolute height="140">
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-6 my-2">
            <router-link to="/" class="d-flex align-center text-decoration-none">
              <v-img
                :src="require('@/assets/images/logos/logo.png')"
                max-height="50px"
                max-width="50px"
                alt="logo"
                contain
                eager
                class="app-logo me-3"
              ></v-img>
              <v-slide-x-transition>
                <h2 class="app-title">INSUFIN</h2>
              </v-slide-x-transition>
            </router-link>
            <v-spacer></v-spacer>
            <div class="justify-left align-end mx-2 pt-6">
              <p>
                {{ localDate }} <br />
                {{ localTime }}
              </p>
            </div>

            <theme-switcher></theme-switcher>

            <app-bar-user-menu></app-bar-user-menu>
          </div>

          <v-divider />

          <div class="py-2 mx-6">
            <v-btn-toggle borderless group active-class="bg-gradient-primary white--text">
              <v-btn style="border-radius: 30px" :to="{ name: 'dashboard' }" text>
                <v-icon left> mdi-home-outline </v-icon>
                Dashboard
              </v-btn>

              <v-btn style="border-radius: 30px" :to="{ name: 'contact-list' }" text>
                <v-icon left> mdi-account-box-multiple-outline </v-icon>
                Contact
              </v-btn>

              <v-btn style="border-radius: 30px" :to="{ name: 'companies' }" text>
                <v-icon left>mdi-domain </v-icon>
                company
              </v-btn>

              <v-btn style="border-radius: 30px" :to="{ name: 'calendarr' }" text>
                <v-icon left> mdi-calendar </v-icon>
                Calendar
              </v-btn>

              <v-btn style="border-radius: 30px" :to="{ name: 'fna-list' }" text>
                <v-icon left> mdi-form-select </v-icon>
                Fna-Form
              </v-btn>

              <v-btn style="border-radius: 30px" :to="{ name: 'filtering' }" text>
                <v-icon left>mdi-poll </v-icon>
                Filtering
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </v-app-bar>

      <v-main>
        <div class="pa-6">
          <slot></slot>
        </div>
      </v-main>
    </v-app>

    <v-app v-else>
      <login />
    </v-app>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
// import { get, sync } from 'vuex-pathify'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Login from '../views/pages/Login.vue'
import NavMenuLink from './components/vertical-nav-menu/components/NavMenuLink.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Login,
    NavMenuLink,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      icons: {
        mdiMagnify,
      },
    }
  },

  data: () => ({
    text: 'center',
    icon: 'justify',
    toggle_none: null,
    toggle_one: 0,
    toggle_exclusive: 2,
    toggle_multiple: [0, 1, 2],
    localDate: ' ',
    localTime: ' ',
  }),

  computed: {
    // ...get('token'),
    // ...sync('token'),
    loggedIn() {
      // return this.token !== '' || localStorage.getItem('login')
      return localStorage.getItem('login')
    },
  },
  mounted() {
    this.showDate()
    this.showLocaleDateTime()
  },
  methods: {
    showLocaleDateTime() {
      const time = this
      setInterval(() => {
        // time.localDate = new Date().toLocaleDateString()
        time.localTime = new Date().toLocaleTimeString()
      }, 1000)
    },
    showDate() {
      const time = this
      let now = new Date().toLocaleDateString('en-us', { weekday: 'long', month: 'long', day: 'numeric' })
      let year = new Date().getFullYear()
      time.localDate = now + ', ' + year
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}
</style>
