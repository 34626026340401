import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VTiptap from '@peepi/vuetify-tiptap'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { relationship } from './store'

Vue.config.productionTip = false

const pinia = createPinia()

Vue.use(PiniaVuePlugin)

Vue.use(VTiptap)

new Vue({
  pinia,
  router,
  relationship,
  vuetify,
  render: h => h(App),
}).$mount('#app')
