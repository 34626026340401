import axios from 'axios'

const http = axios.create({
  // baseURL: 'http://91.107.156.147/',
  // baseURL: 'http://91.247.171.230/',
  // baseURL: 'http://78.39.232.107/',
  // baseURL: "http://44.205.113.229",
  // baseURL: "http://ec2-18-212-79-204.compute-1.amazonaws.com",
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})

http.interceptors.request.use(
  request => request,
  error => Promise.reject(error),
)

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.reload()
    }

    return Promise.reject(error)
  },
)

export default http
