<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-snackbar :timeout="2000" :value="showSnack" top right :color="snackColor" tile class="mx-4 mt-12">
        <strong>{{ snackMessage }}</strong>
      </v-snackbar>

      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="40px"
              max-width="40px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">INSUFIN</h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to INSUFIN! 👋🏻</p>
          <p class="mb-2">Please sign-in to your account and start the adventure</p>
        </v-card-text>

        <v-card-text>
          <v-form v-model="valid">
            <v-text-field
              v-model="editedItem.email"
              outlined
              label="Email"
              placeholder="john@example.com"
              class="mb-3"
              :rules="emailRules"
              required
            ></v-text-field>

            <v-text-field
              v-model="editedItem.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="passwordRules"
              required
              :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox> -->

              <!-- forgot link -->
              <!-- <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a> -->
            </div>

            <v-btn block color="primary" class="mt-6" :disabled="!valid" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>

        <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'pages-register' }"> Create an account </router-link>
        </v-card-text> -->
      </v-card>
    </div>

    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import http from '../../services/http-common'

export default {
  data: () => ({
    valid: false,
    emailRules: [v => !!v || 'Required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    rules: [v => !!v || 'Required'],
    passwordRules: [v => !!v || 'Required', v => (v && v.length >= 8) || 'Password must be more than 8 characters'],
    isPasswordVisible: false,
    editedItem: {
      email: '',
      password: '',
    },
    showSnack: false,
    snackMessage: '',
    snackColor: '',
  }),

  watch: {
    showSnack(val) {
      val || this.closeSnack()
    },
  },

  methods: {
    login() {
      http
        .post(`/auth/login/`, this.editedItem)
        .then(res => {
          localStorage.setItem('login', true)
          localStorage.setItem('username', res.data.user.username)
          localStorage.setItem('userid', res.data.user.id)
          localStorage.setItem('token', res.data.token)
          window.location.href = '/'
        })
        .catch(() => {
          this.showSnack = true
          this.snackMessage = 'Something went wrong, please try again.'
          this.snackColor = 'error'
        })
      this.closeSnack()
    },

    closeSnack() {
      this.showSnack = false
      this.snackMessage = ''
      this.snackColor = ''
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
